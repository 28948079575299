<template>
  <div class="flex justify-center ">
    <b-card class="border-dashed lg:px-5 my-2 md:my-0 text-sm bg-h-primary shadow-md">
      <p ><span class="font-bold"> تاریخ ایجاد : </span> <span dir="ltr"> {{ticketInfo.created_at | p-date}} </span></p>
      <p><span class="font-bold"> وضعیت : </span><span :style="{ color: statusColor(ticketInfo.status_id) }">{{
            statusText(ticketInfo.status_id)
          }}</span></p>
      <p><span class="font-bold"> دپارتمان : </span> {{ticketInfo.category_name}}</p>
      <p><span class="font-bold"> سرویس : </span> {{serviceText(ticketInfo.label_id)}}  </p>
      <p><span class="font-bold"> پاسخ دهنده : </span>  {{ticketInfo.agent_fullname}}</p>
      <p><span class="font-bold"> شماره تیکت : </span>  {{ticketInfo.id}}</p>

      <b-button v-if="ticketInfo.status_id !== 7" variant="dark" class="w-full mt-12" @click="closeTicket()">بستن تیکت </b-button>
    </b-card>
  </div>
</template>

<script>
export default {
  props : ['ticketInfo'],
  methods : {
    closeTicket () {
      this.$http.post(`/ticket/close/${this.ticketInfo.id}`, {satisfied: 1}).then(res => {
        this.$vs.notify({
          color:'success',
          title:'تیکت ',
          text:res.data.message
        })
        this.$router.push('/apps/ticket/')
      }).catch((err) => {
        console.log(err)
      })
    },
    serviceText (id) {
      const arr = ['سرور ابری ایران', 'سرور ابری فرانسه', ' سرور ابری آماده', 'هاست', 'DNS', 'خدمات اختصاصی']
      return arr[+id - 1]
    },
    statusColor (id) {
      const arr = ['#0070C0', ' #46db56', '#00B050', '#FFC000', '#00B0F0', '#fff']
      return arr[+id - 1]
    },
    statusText (id) {
      const arr = ['جدید', 'در انتظار پاسخ', 'پاسخ مشتری', 'پاسخ کارشناس', 'در حال بررسی', 'در دست اقدام', 'بسته شده']
      return arr[+id - 1]
    }
  }
}
</script>
