<template>
  <div class="md:mx-12">
    <b-card class="bg-h-secondary shadow-lg text-grey-100 rounded-lg">
      <div class="px-2 py-1">
        <p>{{ ticketInfo.body }}</p>
        <span dir="ltr" class="flex justify-start">{{
          ticketInfo.created_at
        }}</span>
      </div>
    </b-card>
    <!-- comments -->
    <b-card
      class="shadow-lg my-5 bg-h-primary text-grey-100"
      v-for="(i, index) in ticketInfo.comments"
      :key="index"
      :class="i.comment_side ? 'bg-h-secondary rounded-lg' : 'bg-h-primary rounded-lg'"
    >
      <div v-show="!i.comment_side">
        <span class="dana-font">{{ i.user_fullname }} : </span>
      </div>
      <div class="px-2 py-1">
        <div v-html="i.body"></div>
        <span dir="ltr" class="flex justify-start">{{ i.created_at }}</span>
      </div>
    </b-card>
    <vs-divider class="mb-6"></vs-divider>
    <div>
      <b-textarea
        rows="6"
        class="my-5 bg-h-primary text-grey-100 border-grey-200 border-opacity-25 rounded"
        v-model="message.body"
        placeholder="سوال یا مشکل خود را اینجا بنویسید..."
      />
      <div>
      <!-- <b-form-file
        v-model="message.attachment"
        class="mt-3 w-1/2"
        plain
      ></b-form-file> -->
      <div class="flex justify-end">
        <!-- <b-button  variant="px-12 my-4 "> ارسال فایل </b-button> -->
        <b-button :disabled="loading" class="flex items-center bg-h-secondary border-0 px-4" @click="sendMsg(message)">
          <span class="mx-2">ارسال پیام</span>
          <svg
            v-if="!loading"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-send"
            viewBox="0 0 16 16"
          >
            <path
              d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z"
            />
          </svg>
          <b-spinner v-else small ></b-spinner>
          </b-button
        >
      </div>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  props: ['ticketInfo'],
  data () {
    return {
      message: {
        body: ''
      },
      loading: false
    }
  },
  methods: {
    sendMsg () {
      this.loading = true
      this.$http.post(`/ticket/comment/${this.ticketInfo.id}`, this.message).then(res => {
        this.loading = false
        this.$emit('refresh')
        this.$vs.notify({
          color:'success',
          title:'تیکت ',
          text:res.data.message
        })
      }).catch((err) => {
        console.log(err)
      })
    }
  },
  created () {
  }
}

</script>
