<template>
  <div>
    <vx-card class="bg-h-primary-light">
      
      <!-- headers -->
      <div class="md:flex text-center justify-between md:px-12 mb-12">
        <h4 class="text-white my-4 underline">{{ticketInfo.subject}}</h4>
        <div class="flex items-center gap-2">
          <b-button @click="handleDocLink()" class="text-sm md:text-base md:px-12 px-2 my-4 bg-h-secondary" variant="info">
            <span>مستندات هایو</span>
          </b-button>
          <router-link to="/apps/ticket" class="text-sm md:text-base text-grey border border-dashed border-white border-opacity-25 rounded py-2 px-4">بازگشت به تیکت ها</router-link>

        </div>
      </div>
      <vs-row>
        <vs-col vs-lg="8" vs-xs="12">
          <ChatLog :ticketInfo="ticketInfo" @refresh="getTicketInfo()" />
        </vs-col>
        <vs-col vs-lg="4" vs-xs="12">
          <div style="position: sticky; top: 4rem">
            <TicketInfo :ticketInfo="ticketInfo" />
          </div>
        </vs-col>
      </vs-row>
    </vx-card>
  </div>
</template>

<script>
import ChatLog from './components/ChatLog.vue'
import TicketInfo from './components/TicketInfo.vue'
export default {
  components: {
    ChatLog,
    TicketInfo
  },
  data () {
    return {
      ticketID: '',
      ticketInfo: {}
    }
  },
  methods: {
    handleDocLink () {
      window.open('https://www.haio.ir/category/documentation/')
    },
    getTicketInfo () {
      this.$http.get(`ticket/${this.ticketID}`).then(res => {

        this.ticketInfo = res.data.params.data
      }).catch(err => {
        this.$vs.notify({
          color: 'danger',
          text: err.response.data.message,
          title: 'تیکت'
        })
      })
    }
  },
  created () {
    this.ticketID = this.$route.params.id
    this.getTicketInfo()
  }

}
// while (bug_status != "FIXED") {fix_bug((result) => {test(result)})}
</script>
